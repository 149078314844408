<template>
  <div>
    <h1>Users</h1>
    <p>Here is a list of users:</p>
    <ul>
      <li v-for="user in users" :key="user.id">
          {{ user.name }}
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  mounted () {
    this.getUsers()
  },
  data () {
    return {
      users: []
    }
  },
  methods: {
    async getUsers () {
      try {
        let response

        if (window.location.hostname === 'localhost') {
          response = await fetch(window.location.protocol + '//' + window.location.hostname + '/v1/users')
        } else {
          response = await fetch('https://api.mikachu.de/v1/users')
        }

        response = await response.json()
        this.users = response
      } catch (error) {
        console.error(error)
      }
    }
  }
}

</script>
