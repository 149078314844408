<template>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid blanditiis, fugiat suscipit atque maiores esse nisi molestiae, reprehenderit nihil, delectus maxime? Eum perferendis veniam repellat iste commodi quidem similique a soluta provident ducimus laboriosam quibusdam culpa eius vel consequuntur reiciendis dolorem voluptate est odit, nostrum iusto distinctio labore? Illo temporibus explicabo iure mollitia alias provident voluptatum ratione laudantium! Voluptates quibusdam saepe illum perferendis harum deleniti libero? Obcaecati saepe nihil dignissimos! Beatae fugit error consectetur, culpa iste expedita minima sit laudantium quisquam rerum molestiae ea a earum animi ipsam nam. Voluptas libero ducimus tempora ipsam! Mollitia sed perspiciatis error in expedita.</p>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid blanditiis, fugiat suscipit atque maiores esse nisi molestiae, reprehenderit nihil, delectus maxime? Eum perferendis veniam repellat iste commodi quidem similique a soluta provident ducimus laboriosam quibusdam culpa eius vel consequuntur reiciendis dolorem voluptate est odit, nostrum iusto distinctio labore? Illo temporibus explicabo iure mollitia alias provident voluptatum ratione laudantium! Voluptates quibusdam saepe illum perferendis harum deleniti libero? Obcaecati saepe nihil dignissimos! Beatae fugit error consectetur, culpa iste expedita minima sit laudantium quisquam rerum molestiae ea a earum animi ipsam nam. Voluptas libero ducimus tempora ipsam! Mollitia sed perspiciatis error in expedita.</p>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
